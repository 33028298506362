.page {
    background-color: #F7F8F9;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

* {
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Regular, PingFang SC;
}

html {
    font-size: calc(100vw / 7.5);
}

body {
    margin: 0;
    font-size: .28rem;
    color: #222222;
}

.active {
    color: #FFC300;
}

.bold {
    font-weight: bold !important;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overflow-ellipsis-2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.overflow-ellipsis-3 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
